@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.profile-icon {
  @include onload-animation(slide-down);
  @include v-stack-c;
  @include h-mar($gap-lg);
  text-align: center;
  @include grow;
  .icon {
    @include h-center;
    font-size: $txt-xxl;
  }
  .name {
    opacity: 0;
    font-size: $txt-sm;
    translate: -10px 0px;
  }
  &:hover {
    &,
    & * {
      color: var(--secondary);
    }
    .name {
      @include trans(0.25s);
      opacity: 50%;
      translate: 0px 0px;
    }
  }
}
