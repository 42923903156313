@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.card {
  @include clickable;
  @include onload-animation(slide-right);
  overflow: hidden;
  & * {
    transition: all 0.5s;
  }
  &:hover {
    scale: 1.01;
    .card-bg {
      scale: 1.025;
      -webkit-filter: grayscale(0%);
      filter: grayscale(0%);
    }
    .card-bg-mask {
      opacity: 50%;
    }
    .card-content {
      opacity: 0%;
    }
  }
  .card-bg {
    -webkit-filter: grayscale(60%);
    filter: grayscale(60%);
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: -2;
  }
  .card-bg-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--bg2), var(--bg1));
    opacity: 90%;
    z-index: -1;
  }
  .card-content {
    z-index: 0;
  }
}
