@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
@import "../../../../sass/breakpoints.scss";

.activities-subview {
  @include mobile-down {
    @include v-stack-c;
  }
  @include mobile-up {
    @include col-grid(1fr 1fr 1fr);
  }
  text-align: center;
  .residencies,
  .lectures,
  .workshops {
    @include v-stack;
    @include v-pad;
    @include to-top;
  }
  .header {
    text-transform: uppercase;
    text-align: center;
  }
  .residence,
  .workshop,
  .lecture {
    @include vh-pad($gap-ml, $gap-xl);
    @include mar;
    @include round-border;
    background-color: var(--bg1);
  }
  .lecture > .name,
  .residence > .role,
  .workshop > .name {
    @include v-stack;
    @include h-center;
    text-align: center;
    font-weight: bold;
    font-style: italic;
    color: var(--primary);
  }
  .lecture .name,
  .residence .event,
  .workshop .institution {
    @include v-stack;
    text-align: center;
    font-weight: bold;
  }

  .lecture .event,
  .workshop .event {
    @include v-pad;
  }
  .activities {
    font-weight: bold;
    .residence-activity {
      font-style: italic;
    }
  }
  .language {
    text-transform: uppercase;
  }
}
