@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";
@import "../../../sass/breakpoints.scss";

.cv-view {
  @include b-mar($gap-xl);
  @include pad;
  .cv-header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    .cv-download {
      @include to-left;
      @include to-bottom;
      @include l-pad($gap-ml);
      @include b-pad($gap-md);
      @include mobile-down {
        font-size: $txt-sm;
      }
    }
  }
  .closed .icon {
    -webkit-writing-mode: horizontal-tb;
    -ms-writing-mode: lr-tb;
    writing-mode: horizontal-tb;
    @include mobile-up {
      transform: rotate(180deg);
    }
  }
  .icon {
    font-size: $txt-lg;
    * {
      color: var(--secondary);
    }
  }
  .cv-section-title {
    vertical-align: middle;
  }
  .open .icon {
    vertical-align: middle;
    text-align: middle;
  }
}
