@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
@import "../../../../sass/breakpoints.scss";

.expandable-slide {
  @include v-stack;
  @include round-border;
  @include center;
  @include mar;
  width: 100%;
  height: 100%;
  overflow: auto;
  .slide-title {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.open {
    background-color: var(--bg2trans);
    .slide-content {
      * {
        @include onload-animation(fade-in, $dur: 0.5s);
      }
    }
    @include mobile-up {
      width: 100%;
    }
    @include mobile-down {
      max-height: 400px;
    }
    .slide-title {
      display: none;
      z-index: 2;
    }
  }
  &.closed {
    @include mobile-up {
      width: 5vw;
      min-width: 5vw;
      .slide-title {
        -webkit-writing-mode: tb-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
      }
    }
  }
  &.closed,
  &:not(.open) {
    @include clickable;
    @include grow;
    --center-col: var(--bg1trans);
    .dark & {
      --center-col: var(--bg3trans);
    }
    background: linear-gradient(
      60deg,
      var(--bord-trans) 0%,
      var(--center-col) 100%
    );
    &:hover {
      background-color: var(--bg-hl-trans);
    }
    .slide-content {
      display: none;
    }
    .cv-section-title {
      @include h-stack-c;
    }
  }
  .slide-title,
  .slide-content > .slide-title {
    @include v-stack-c;
    @include v-center;
    @include v-pad;
    @include h-mar(auto);
    font-size: $txt-ml;
    text-transform: uppercase;
    text-align: center;
  }
  .slide-content > .slide-title {
    margin-top: 0;
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    background-color: var(--bg3);
    color: var(--primary);
    top: 0;
  }
  .slide-content {
    width: 100%;
    height: calc(100% - 32px);
  }
}
