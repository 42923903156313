@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";
@import "../../../sass/breakpoints.scss";

.home-view {
  @include v-stack-c;
  @include mobile-down {
    @include v-stack-c;
  }
  @include center;
  @include pad($gap-lg);
  min-height: 50vh;
  & * {
    @include wrap;
  }
  .header {
    @include v-stack-c;
    @include center;
    @include v-pad($gap-xl);
    min-width: 50%;
    .text {
      @include h-stack-c;
      @include v-pad($gap-xl);
      @include h-center;
      .name {
        @include stack;
        @include h-center;
        @include r-pad;
        color: var(--primary);
        text-transform: lowercase;
      }
      .name,
      .label-text,
      .decorator {
        @include onload-animation(fade-in, $dur: 2s);
        @include stack;
        @include h-pad;
        font-size: $txt-xl;
        @include mobile-down {
          &,
          & > * {
            font-size: $txt-lg;
          }
        }
        @include mobile-small-down {
          &,
          & > * {
            font-size: $txt-ml;
          }
        }
      }
      .labels {
        @include h-stack-c;
        @include h-center;
        justify-content: center;
        .label {
          @include h-stack-c;
          // @include h-center;
          width: -webkit-max-content;
          width: -moz-max-content;
          width: max-content;
          justify-content: space-around;
          .label-text {
            color: var(--secondary);
            font-style: italic;
          }
        }
      }
    }
    .profiles {
      @include h-stack-c;
      @include h-center;
      justify-content: center;
    }
  }
  .bio {
    @include vh-pad($gap-lg, $gap-xl);
    background-color: var(--bg2trans);
    min-width: 100px;
    .bio-header {
      @include b-mar;
      color: var(--primary);
      font-size: $txt-lg;
      text-align: center;
      text-transform: uppercase;
    }
    .bio-p {
      @include v-pad;
      color: var(--txt);
      font-size: $txt-ml;
      font-weight: lighter;
      text-align: left;
    }
    .header {
      text-align: center;
      text-transform: uppercase;
    }
    .interests {
      @include pad;
      @include h-stack;
      @include wrap;
      justify-content: center;
      .interest {
        @include pad($gap-sm);
        @include b-mar($gap-sm);
        @include r-mar;
        @include round-border;
        text-align: center;
        font-family: $font2;
        text-transform: lowercase;
        background-color: var(--bg1);
      }
      * {
        font-size: $txt-ml;
      }
    }
  }
}
