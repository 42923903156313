@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.works-view {
  @include v-stack-c;
  @include v-pad;
  $border-size: $border-ml;
  $border-col: var(--bord);
  border-top: $border-size solid $border-col;
  border-bottom: $border-size solid $border-col;
  background-color: var(--bg4trans);
  width: 100%;
  .work-cards {
    @include row-grid(1fr 1fr);
    @include h-pad($gap-lg);
    grid-auto-flow: column;
    width: 100%;
    overflow-y: auto;
  }
}
