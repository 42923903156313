@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.modal {
  .modal-close-btn {
    $modal-close-btn-margin: $gap-ml;
    @include link;
    border-radius: 100%;
    font-size: $txt-lg;
    position: fixed;
    top: $modal-close-btn-margin;
    right: $modal-close-btn-margin;
  }
}
