@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.view-header {
  @include v-pad($gap-xl);
  text-align: center;
  .title {
    font-size: $txt-xl;
    text-transform: uppercase;
  }
  .icon {
    & * {
      color: var(--secondary);
    }
    font-size: $txt-xl;
    vertical-align: middle;
    top: 50%;
    left: 50%;
    translate: -25% -25%;
  }
}
