@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.shaders-view {
	@include h-stack-c;
	@include v-pad;
	@include v-mar;
	background-color: var(--bg4trans);
	border-bottom: $border-ml solid var(--bord);
	border-top: $border-ml solid var(--bord);
	overflow: auto;
	.shader {
		$height: 300px;
		$width: calc($height * 16 / 10);
		@include stack;
		@include mar;
		@include round-border;
		height: $height;
		width: $width;
		min-width: $width;
	}
}