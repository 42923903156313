@import "../../../../sass/mixins.scss";
@import "../../../../sass/variables.scss";
@import "../../../../sass/breakpoints.scss";

.skills-subview {
  @include pad;
  @include mar;
  @include v-stack-c;
  .header {
    @include v-stack-c;
    @include pad;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }
  .languages .name {
    text-transform: capitalize;
  }
  .technology,
  .languages {
    @include h-stack-c;
    @include wrap;
    justify-content: center;
    .skill {
      @include v-stack;
      @include round-border;
      @include pad;
      @include mar;
      background-color: var(--bg1);
      .level {
        border-radius: $border-md;
        position: static;
        background-color: var(--level1);
        $level-h: 5px;
        height: $level-h;
        min-height: $level-h;
      }
      .skill-info {
        @include v-stack;
        .name {
          @include stack;
          @include v-mar;
          font-weight: bold;
          color: var(--primary);
          height: 50%;
          vertical-align: middle;
          align-items: center;
        }
        justify-content: center;
        align-items: center;
        text-align: center;
        white-space: pre-wrap;
        $box-size: 190px;
        width: $box-size;
        min-height: calc($box-size * 0.5);
        .keywords {
          @include h-stack-c;
          @include wrap;
          @include mar;
          text-transform: lowercase;
          justify-content: center;
          .keyword {
            @include pad($gap-sm);
            @include b-mar($gap-sm);
            @include r-mar;
            @include round-border;
            font-family: $font2;
            font-size: $txt-sm;
          }
        }
      }
    }
  }
}
