/* font */

/* palette */

:root {
  --bg1: #f7f7f7;
  --bg1trans: #f7f7f799;
  --bg2: #efefef;
  --bg2trans: #efefefb7;
  --bg3: #dedede;
  --bg3trans: #dededeb7;
  --bg4: #cfcfcf;
  --bg4trans: #cfcfcfb7;
  --bord: #4b5765;
  --bord-trans: #4b576511;
  --txt: #292929;
  --primary: #415f79;
  --secondary: #4a88b8;
  --danger: #d0573f;
  --bg-hl: #f7f7f7;
  --bg-hl-trans: #f7f7f7b7;
  --bg-sel: #d8d8d8;
  --inactive: #6b6b6b;
  --glow: #fff4a377;
  --level1: #4e97ce;
  --level2: #4ea3ce;
  --level3: #4ececa;
  --level4: #4eceb0;
  --level5: #4ece8a;
  --audio: var(--level1);
}

.dark {
  --bg1: #1d1d1d;
  --bg1trans: #1d1d1dfb;
  --bg2: #222222;
  --bg2trans: #222222b7;
  --bg3: #161616;
  --bg3trans: #161616b7;
  --bg4: #101010;
  --bg4trans: #101010b7;
  --bord: #445d76;
  --bord-trans: #445d7608;
  --txt: #d9d9d9;
  --primary: #94acb9;
  --secondary: #73bbd3;
  --danger: #d86e58ff;
  --bg-hl: #313131;
  --bg-hl-trans: #313131b7;
  --bg-sel: #3b3a39;
  --inactive: #949494;
  --glow: #28272755;
}

/* fonts */
$font1: "Lato", sans-serif;
$font2: "Ubuntu Mono", monospace;

/* sizes */
$gap-xs: 1px;
$gap-sm: 2.5px;
$gap-ms: 3.5px;
$gap-md: 5px;
$gap-ml: 7px;
$gap-lg: 10px;
$gap-xl: 15px;
$gap-xxl: 25px;

$txt-xs: 10px;
$txt-sm: 12px;
$txt-md: 15px;
$txt-ml: 18px;
$txt-lg: 24px;
$txt-xl: 30px;
$txt-xxl: 40px;

$border-sm: 1px;
$border-md: 1.5px;
$border-ml: 3px;
$border-lg: 5px;
$border-xl: 10px;

$radius-sm: 2.5px;
$radius-md: 5px;

$grow: 1.025;

$work-img-size: 225px;

$navbar-size: 280px;
