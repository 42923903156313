@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.work-modal {
  @include v-stack;
  @include pad($gap-lg);
  @include h-pad($gap-xxl);
  @include round-border($size: $border-lg);
  background-color: var(--bg3);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 90vh;
  max-width: 90vw;
  overflow: auto;
  .header {
    @include h-stack;
    @include h-center;
    flex-wrap: wrap;
    justify-content: center;
    * {
      @include r-pad;
      font-size: $txt-ml;
    }
    .work-name {
      text-align: center;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      font-weight: bold;
    }
    .work-duration {
      color: var(--inactive);
    }
  }
  .subheader {
    @include v-stack-c;
    .work-subtitle,
    .work-commission {
      @include b-mar($gap-lg);
      font-style: italic;
      text-align: center;
    }
    .work-subtitle {
      color: var(--secondary);
    }
  }
  .notes {
    @include v-stack-c;
    white-space: pre-wrap;
    color: var(--inactive);
    @include vh-pad($gap-lg, $gap-xxl);
    border-radius: $border-lg;
    font-style: italic;
    max-width: 600px;
  }
  .checkout {
    @include h-stack;
    @include v-pad;
    width: 100%;
    justify-content: center;
    .checkout__link {
      @include round-border;
      @include clickable;
      @include vh-pad($gap-md, $gap-lg);
      background-color: var(--bord);
      &:hover {
        background-color: var(--secondary);
      }
      color: white;
      font-size: $txt-sm;
      text-transform: uppercase;
    }
  }
  .table {
    border-spacing: $gap-lg $gap-lg;
    .table-body {
      .table-row {
        .row-key {
          font-style: italic;
          vertical-align: top;
          width: 1%;
        }
        .row-value {
          @include stack;
          @include wrap;
          .row-value-item {
            &.instrumentation,
            &.categories {
              @include pad($gap-sm);
              @include b-mar($gap-sm);
              @include r-mar;
              @include round-border;
              font-family: $font2;
              background-color: var(--bg1);
              color: var(--primary);
              text-transform: lowercase;
            }
          }
          &.awards {
            @include v-stack;
            .award-name {
              font-weight: bold;
            }
            .award-institution {
              font-style: italic;
            }
          }
          &:has(> .performance) {
            @include v-stack;
          }
          .performance {
            @include v-pad($gap-ms);
            span,
            a {
              @include r-pad;
            }
            .event {
              @include h-stack;
              font-weight: bold;
            }
            .media {
              * {
                @include t-mar($gap-md);
                font-size: $txt-lg;
                vertical-align: middle;
                transition-duration: 0.2s;
              }
              &:hover {
                * {
                  filter: brightness(112%);
                }
              }
              &.video * {
                color: var(--danger);
              }
              &.audio * {
                color: var(--audio);
              }
            }
            .performers {
              @include l-pad($gap-xl);
              .performer-role {
                color: var(--inactive);
                font-style: italic;
              }
            }
            .separator {
              @include t-mar($gap-ml);
              @include l-mar($gap-md);
              height: $gap-sm;
              width: 10%;
            }
          }
        }
      }
    }
  }
}
