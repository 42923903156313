@import "./variables.scss";
* {
  font-family: $font1;
  font-weight: 400;
  text-decoration: none;
  font-size: $txt-md;
  color: var(--txt);
  border-color: var(--bord);
  outline-width: $border-sm;
  outline-color: var(--bg-hl);
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  border-style: none;
  transition-property: color, background-color;
  transition-duration: 0.6s;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
}

a {
  @include link;
}

hr {
  background-color: var(--bord);
  border-radius: $radius-md;
  height: $gap-xs;
}
