@import "../../../sass/mixins.scss";
@import "../../../sass/variables.scss";

.shadertoy {
	z-index: 3;
  .inner {
    width: 100%;
  }
	&:not(.clicked) {
		@include trans;
		opacity: 50%;
		&:hover {
			opacity: 90%;
		}
	}
}
